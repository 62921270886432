import { Card, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDTypography from 'components/MDTypography';
import { useAuthContext } from 'contexts/auth';
import { setLoading, useThemeContext } from 'contexts/theme';
import Page from 'layouts/Page';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { checkEmail } from 'utils/stringUtil';

export default function ResetPassword() {
  const [themeContext, dispatch] = useThemeContext();
  const { loading } = themeContext;

  const { resetPassword } = useAuthContext();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [snackbar, setSnakBar] = useState({
    color: 'success',
    title: '',
    content: '',
    open: false,
  });

  const [openConfirmResetPasswordDialog, setOpenConfirmResetPasswordDialog] =
    useState(false);

  const handleOpenConfirmResetPasswordDialog = () => {
    setOpenConfirmResetPasswordDialog(true);
  };

  const handleCloseConfirmResetPasswordDialog = () => {
    setOpenConfirmResetPasswordDialog(false);
  };

  const toggleSnackbar = () =>
    setSnakBar({ ...snackbar, open: !snackbar.open });

  const handleChange = event => {
    setEmail(event.target.value);
    setEmailError(checkEmail(email));
  };

  const handleResetPassword = async event => {
    event.preventDefault();
    setEmailError('');
    setOpenConfirmResetPasswordDialog(false);
    if (!loading) {
      setLoading(dispatch, true);
    }

    setEmailError(checkEmail(email));

    if (emailError !== '') {
      setLoading(dispatch, false);
      return;
    }

    await resetPassword(email).catch(err => {
      setEmailError(err.message);
      setLoading(dispatch, false);
      setSnakBar({
        color: 'error',
        title: 'Error',
        content: 'Send password email is been failed',
        open: true,
      });
    });
    if (!emailError) {
      setLoading(dispatch, false);
      setSnakBar({
        color: 'success',
        title: 'Success',
        content: 'Reset password email is been sent successfully',
        open: true,
      });
    }
  };

  return (
    <Page>
      <Dialog
        open={openConfirmResetPasswordDialog}
        onClose={handleCloseConfirmResetPasswordDialog}
      >
        <DialogTitle>Are you sure delete this?</DialogTitle>
        <DialogActions>
          <MDButton
            variant="text"
            onClick={handleCloseConfirmResetPasswordDialog}
          >
            Cancel
          </MDButton>
          <MDButton variant="text" onClick={handleResetPassword} autoFocus>
            Send
          </MDButton>
        </DialogActions>
      </Dialog>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Send password reset email to registered email address
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={email}
                error={!!emailError.email}
                helperText={emailError.email}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="button"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleOpenConfirmResetPasswordDialog}
              >
                Send
              </MDButton>
            </MDBox>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Grid item xs>
                <MDBox textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs>
                <MDBox textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color={snackbar.color}
        dateTime=""
        icon="notifications"
        title={snackbar.title}
        content={snackbar.content}
        open={snackbar.open}
        close={toggleSnackbar}
      />
    </Page>
  );
}
