import { useAuthContext } from 'contexts/auth';

const { Outlet, useLocation, Navigate } = require('react-router-dom');

function Private() {
  const { isSignedIn } = useAuthContext();
  const location = useLocation();

  return isSignedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default Private;
