import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import Footer from 'components/MDFooter';
import { PropTypes } from 'prop-types';

function Page({ background, children }) {
  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: 'hidden' }}
    >
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={5}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer light />
    </MDBox>
  );
}

Page.defaultProps = {
  background: 'default',
};

// Typechecking props for the PageLayout
Page.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
};

export default Page;
