import logo from 'assets/images/logo.png';
import MDBox from 'components/MDBox';
import Configurator from 'components/MDConfigurator';
import Navbar from 'components/MDNavbar';
import Sidenav from 'components/MDSidenav';
import { setLayout, setMiniSidenav, useThemeContext } from 'contexts/theme';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { menus } from 'routers';

function Home({ children }) {
  const [themeContext, dispatch] = useThemeContext();
  const { miniSidenav, sidenavColor } = themeContext;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <Sidenav
        color={sidenavColor}
        brand={logo}
        brandName="QX Minuts"
        menus={menus}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      <Navbar />
      {children}
    </MDBox>
  );
}

Home.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Home;
