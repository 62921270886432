import { Card, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDTypography from 'components/MDTypography';
import { useAuthContext } from 'contexts/auth';
import { setLoading, useThemeContext } from 'contexts/theme';
import Page from 'layouts/Page';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { checkEmail, checkPassword } from 'utils/stringUtil';

export default function SignUp() {
  const [themeContext, dispatch] = useThemeContext();
  const { loading } = themeContext;

  const { signUp } = useAuthContext();
  const [signUpForm, setSignUpForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    agreement: false,
  });
  const [signUpError, setSignUpError] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    agreement: '',
    form: '',
  });
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const validateForm = (name, value) => {
    if (name === 'email') {
      setSignUpError({ ...signUpError, [name]: checkEmail(value) });
    }
    if (name === 'password') {
      setSignUpError({ ...signUpError, [name]: checkPassword(value) });
    }
    if (name === 'confirmPassword') {
      setSignUpError({
        ...signUpError,
        [name]: signUpForm.password !== value ? 'Password not match' : '',
      });
    }
    if (name === 'firstName' || name === 'lastName' || name === 'companyName') {
      setSignUpError({ ...signUpError, [name]: !value ? "It's empty" : '' });
    }
    if (name === 'agreement') {
      setSignUpError({
        ...signUpError,
        [name]: !value ? 'You must agree with terms and policy' : '',
      });
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setSignUpForm(values => ({ ...values, [name]: value }));
    validateForm(name, value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!loading) {
      setLoading(dispatch, true);
    }

    setSignUpError({
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      companyName: '',
      agreement: '',
      form: '',
    });
    setOpenErrorSnackbar(false);

    Object.entries(signUpForm).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(signUpForm, key)) {
        setSignUpError({ ...signUpError, [key]: 'Not exist' });
      }
      validateForm(key, value);
    });

    if (Object.entries(signUpError).filter(v => v[1] !== '').length > 0) {
      setLoading(dispatch, false);
      return;
    }

    const { email, password, firstName, lastName, companyName } = signUpForm;

    setSignUpError({ ...signUpError, form: '' });
    await signUp(email, password, firstName, lastName, companyName)
      .catch(err => {
        setSignUpError({ ...signUpError, form: err.message });
        setOpenErrorSnackbar(true);
        setLoading(dispatch, false);
      })
      .finally(() => {
        setLoading(dispatch, false);
      });
  };

  return (
    <Page>
      <MDSnackbar
        color="error"
        dateTime=""
        icon="notifications"
        title="ERROR"
        content={signUpError.form}
        open={openErrorSnackbar}
        close={() => {
          setOpenErrorSnackbar(false);
          setSignUpError({ ...signUpError, form: '' });
        }}
      />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your account information to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={signUpForm.email}
                error={!!signUpError.email}
                helperText={signUpError.email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label="Password"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={signUpForm.password}
                error={!!signUpError.password}
                helperText={signUpError.password}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={signUpForm.confirmPassword}
                error={!!signUpError.confirmPassword}
                helperText={signUpError.confirmPassword}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                name="companyName"
                label="Company Name"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={signUpForm.companyName}
                error={!!signUpError.companyName}
                helperText={signUpError.companyName}
              />
            </MDBox>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="firstName"
                    label="First Name"
                    variant="standard"
                    fullWidth
                    required
                    onChange={handleChange}
                    value={signUpForm.firstName}
                    error={!!signUpError.firstName}
                    helperText={signUpError.firstName}
                  />
                </MDBox>
              </Grid>
              <Grid item xs>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="lastName"
                    label="Last Name"
                    variant="standard"
                    fullWidth
                    required
                    onChange={handleChange}
                    value={signUpForm.lastName}
                    error={!!signUpError.lastName}
                    helperText={signUpError.lastName}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                name="agreement"
                checked={signUpForm.agreement}
                onChange={event =>
                  setSignUpForm(values => ({
                    ...values,
                    [event.target.name]: event.target.checked,
                  }))
                }
                value={signUpForm.agreement}
              />
              <MDTypography variant="button" color="text">
                I agree the{' '}
                <MDTypography
                  component={Link}
                  to="https://www.qualitax.io/terms"
                  target="_blank"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Terms and Conditions
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Sign Up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{' '}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </Page>
  );
}
