import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import ConfiguratorRoot from 'components/MDConfigurator/ConfiguratorRoot';
import MDTypography from 'components/MDTypography';
import { useAuthContext } from 'contexts/auth';
import {
  setDarkMode,
  setOpenConfigurator,
  useThemeContext,
} from 'contexts/theme';

function Configurator() {
  const { signOut } = useAuthContext();

  const [themeContext, dispatch] = useThemeContext();
  const { openConfigurator, darkMode } = themeContext;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Configurator</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: 'currentColor',
            strokeWidth: '2px',
            cursor: 'pointer',
            transform: 'translateY(5px)',
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography variant="h6">Dark Mode</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <Divider />
        <MDBox mt={3} mb={2}>
          <MDButton variant="gradient" color="info" fullWidth onClick={signOut}>
            sign out
          </MDButton>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
