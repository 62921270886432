import {
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDDataTable from 'components/MDDataTable';
import MDSnackbar from 'components/MDSnackbar';
import MDTypography from 'components/MDTypography';
import { db, storage } from 'config/firebase';
import { useAuthContext } from 'contexts/auth';
import { setLoading, useThemeContext } from 'contexts/theme';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  ref,
  uploadString,
} from 'firebase/storage';
import { useEffect, useState } from 'react';
import { decryptData, encryptData } from 'services/crypto';

function Transcripts() {
  const { userData } = useAuthContext();
  const firestorePath = 'transcripts';

  const [, dispatch] = useThemeContext();

  const [list, setList] = useState([]);

  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openDeleteDialog, setDeleteDialog] = useState(false);
  const [targetData, setTargetData] = useState({});

  const handleOpenDialog = target => {
    setTargetData(target);
    setDeleteDialog(true);
  };
  const handleCloseDialog = () => {
    setDeleteDialog(false);
    setTargetData({});
  };
  const handleDelete = async () => {
    setLoading(dispatch, true);
    setDeleteDialog(false);
    if (targetData) {
      await deleteDoc(doc(db, `${firestorePath}/${targetData.id}`));
      await deleteObject(
        ref(
          storage,
          `${firestorePath}/${targetData.id}/${targetData.filename}`,
        ),
      );
    }
    setTargetData({});
    setLoading(dispatch, false);
  };

  useEffect(() => {
    const q = query(
      collection(db, firestorePath),
      where('uid', '==', userData.uid),
      orderBy('created', 'desc'),
    );
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const arr = querySnapshot.docs.map(d => ({
        id: d.id,
        ...d.data(),
      }));
      setList(arr);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const validateFile = async f =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const contents = reader.result;

        if (contents.length > 90000) {
          resolve(false);
          setError('File is too big');
          setOpenSnackbar(true);
        }
        resolve(true);
      };
      reader.readAsText(f);
    });

  const handleUpload = async e => {
    try {
      e.preventDefault();
      setLoading(dispatch, true);
      setOpenSnackbar(false);
      setError('');
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        if (!(await validateFile(file))) {
          return;
        }

        const added = await addDoc(collection(db, firestorePath), {
          uid: userData.uid,
          title: '',
          created: serverTimestamp(),
          filename: file.name,
        });

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          const encdataurl = await encryptData(
            reader.result,
            userData.key.public,
          );
          const fileRef = ref(storage, `transcripts/${added.id}/${file.name}`);
          await uploadString(fileRef, encdataurl);
          const url = await getDownloadURL(fileRef);
          await updateDoc(added, { url });
        };
      }
    } catch (err) {
      console.error(err);
      setError('Failed to upload');
      setOpenSnackbar(true);
      setLoading(dispatch, false);
    } finally {
      setOpenSnackbar(true);
      setLoading(dispatch, false);
    }
  };

  const handleDownload = async (url, id, filename) => {
    try {
      setLoading(dispatch, true);
      const fileRef = ref(storage, url);
      const blob = await getBlob(fileRef);
      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = async () => {
        const decData = await decryptData(reader.result, userData.key.private);
        const arr = decData.message.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const decfile = new File([u8arr], filename, {
          type: mime,
        });
        const fileurl = URL.createObjectURL(decfile);
        // window.open(fileurl);
        const a = document.createElement('a');
        a.href = fileurl;
        a.download = filename;
        a.click();
      };
    } catch (err) {
      console.error(err);
      setLoading(dispatch, false);
    } finally {
      setLoading(dispatch, false);
    }
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    setError('');
  };

  const updateTitle = async (id, title) => {
    await updateDoc(doc(db, `${firestorePath}/${id}`), { title });
  };

  return (
    <MDBox>
      <Dialog open={openDeleteDialog} onClose={handleCloseDialog}>
        <DialogTitle>Are you sure delete this?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" onClick={handleCloseDialog}>
            Cancel
          </MDButton>
          <MDButton variant="text" onClick={handleDelete} autoFocus>
            OK
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={error ? 'error' : 'success'}
        icon="notifications"
        dateTime=""
        title={error ? 'ERROR' : 'SUCCESS'}
        content={error || 'File is been successfully uploaded'}
        open={openSnackbar}
        close={closeSnackbar}
      />
      <MDDataTable
        action={
          <MDBox ml={2}>
            <MDButton variant="contained" color="info" component="label">
              <input
                accept=".txt, .vtt"
                hidden
                multiple
                type="file"
                onChange={handleUpload}
                required
              />
              <Icon
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                fontSize="medium"
              >
                add
              </Icon>
              &nbsp;ADD
            </MDButton>
          </MDBox>
        }
        table={{
          columns: [
            { Header: 'title', accessor: 'title', width: '30%' },
            { Header: 'file', accessor: 'filename', width: '40%' },
            {
              Header: 'Created Date',
              accessor: 'createdDate',
            },
            { Header: 'delete', accessor: 'delete', width: '15%' },
          ],
          rows: [
            ...list.map(data => ({
              title: (
                <TextField
                  variant="standard"
                  value={data.title}
                  onChange={e => {
                    setList(
                      list.map(i => {
                        const d = i;
                        if (d.id === data.id) {
                          d.title = e.target.value;
                        }
                        return d;
                      }),
                    );
                  }}
                  onBlur={e => updateTitle(data.id, e.target.value)}
                  fullWidth
                />
              ),
              filename: (
                <MDTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={() =>
                    handleDownload(data.url, data.id, data.filename)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {data.filename}
                </MDTypography>
              ),
              createdDate: data.created
                ? data.created.toDate().toISOString().split('T')[0]
                : '',
              delete: (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  onClick={() => handleOpenDialog(data)}
                >
                  <Icon
                    sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                    fontSize="small"
                  >
                    delete
                  </Icon>
                </IconButton>
              ),
            })),
          ],
        }}
      />
    </MDBox>
  );
}
export default Transcripts;
