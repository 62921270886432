import { Button, Icon, Paper, Typography } from '@mui/material';

function NotFound() {
  return (
    <Paper
      sx={{
        backgroundColor: t => t.palette.background.default,
        margin: 0,
        height: `100vh`,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: `100%`,
        }}
      >
        <Typography variant="h4">404</Typography>
        <Typography variant="subtitle1">Page Not Found</Typography>
        <Button
          color="secondary"
          aria-label="home"
          href="/"
          style={{ marginTop: 20 }}
        >
          <Icon>home</Icon>
        </Button>
      </div>
    </Paper>
  );
}

export default NotFound;
