import { useAuthContext } from 'contexts/auth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function Public() {
  const location = useLocation();
  const from = location.state?.from || '/home';

  const { isSignedIn } = useAuthContext();

  return isSignedIn ? <Navigate to={from} replace /> : <Outlet />;
}

export default Public;
