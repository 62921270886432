import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDDataTable from 'components/MDDataTable';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { db, storage } from 'config/firebase';
import { useAuthContext } from 'contexts/auth';
import { setLoading, useThemeContext } from 'contexts/theme';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { deleteObject, getBlob, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { decryptData } from 'services/crypto';

function MeetingMinutes() {
  const { userData } = useAuthContext();
  const rootPath = 'meetingMinutes';
  const transcriptsPath = 'transcripts';
  const templatesPath = 'templates';

  const [, dispatch] = useThemeContext();

  const [meetingMinutes, setMeetingMinutes] = useState([]);
  const [openGenerateDialog, setOpenGenerateDialog] = useState(false);
  const [transcripts, setTranscripts] = useState([]);
  const [targetTranscript, setTargetTranscript] = useState('');
  const [templates, setTemplates] = useState([]);
  const [targetTemplate, setTargetTemplate] = useState('');
  const [outputFormat, setOutputFormat] = useState('txt');

  const [openDeleteDialog, setDeleteDialog] = useState(false);
  const [targetData, setTargetData] = useState({});

  const handleOpenGenerateDialog = () => {
    setTargetTranscript('');
    setTargetTemplate('');
    setOutputFormat('txt');
    setOpenGenerateDialog(true);
  };

  const handleCloseGenerateDialog = () => {
    setTargetTranscript('');
    setTargetTemplate('');
    setOutputFormat('txt');
    setOpenGenerateDialog(false);
  };

  const generateMeetingMinutes = async () => {
    if (userData.credit < 1) {
      handleCloseGenerateDialog();
    }
    const transcript = transcripts.find(t => t.id === targetTranscript);
    const template = templates.find(t => t.id === targetTemplate);
    if (!template || !transcript) {
      return;
    }
    await addDoc(collection(db, rootPath), {
      uid: userData.uid,
      loading: true,
      created: serverTimestamp(),
      transcript: {
        id: transcript.id,
        filename: transcript.filename,
      },
      template: {
        id: template.id,
        filename: template.filename,
      },
      outputFormat,
      key: userData.key,
      error: '',
    });
    handleCloseGenerateDialog();
  };

  const handleOpenDeleteDialog = target => {
    setTargetData(target);
    setDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
    setTargetData({});
  };

  const handleDelete = async () => {
    setLoading(dispatch, true);
    setDeleteDialog(false);
    if (targetData) {
      await deleteDoc(doc(db, `${rootPath}/${targetData.id}`));
      if (targetData.filename) {
        await deleteObject(
          ref(storage, `${rootPath}/${targetData.id}/${targetData.filename}`),
        );
      }
    }
    setTargetData({});
    setLoading(dispatch, false);
  };

  useEffect(() => {
    const meetingMinutesQuery = query(
      collection(db, rootPath),
      where('uid', '==', userData.uid),
      orderBy('created', 'desc'),
    );
    const transcriptsQuery = query(
      collection(db, transcriptsPath),
      where('uid', '==', userData.uid),
      orderBy('created', 'desc'),
    );
    const templatesQuery = query(
      collection(db, templatesPath),
      where('uid', '==', userData.uid),
      orderBy('created', 'desc'),
    );

    const unsubscribe = () => {
      onSnapshot(meetingMinutesQuery, querySnapshot => {
        const arr = querySnapshot.docs.map(d => ({
          id: d.id,
          ...d.data(),
        }));
        setMeetingMinutes(arr);
      });

      onSnapshot(transcriptsQuery, querySnapshot => {
        const arr = querySnapshot.docs.map(d => ({
          id: d.id,
          ...d.data(),
        }));
        setTranscripts(arr);
      });

      onSnapshot(templatesQuery, querySnapshot => {
        const arr = querySnapshot.docs.map(d => ({
          id: d.id,
          ...d.data(),
        }));
        setTemplates(arr);
      });
    };

    return unsubscribe();
  }, []);

  const handleDownload = async (url, id, filename) => {
    try {
      setLoading(dispatch, true);
      const fileRef = ref(storage, `${rootPath}/${id}/${filename}`);
      const blob = await getBlob(fileRef);
      const reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = async () => {
        const decData = await decryptData(reader.result, userData.key.private);
        const arr = decData.message.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const decfile = new File([u8arr], filename, {
          type: mime,
        });
        const fileurl = URL.createObjectURL(decfile);
        // window.open(fileurl);
        const a = document.createElement('a');
        a.href = fileurl;
        a.download = filename;
        a.click();
      };
    } catch (err) {
      console.error(err);
      setLoading(dispatch, false);
    } finally {
      setLoading(dispatch, false);
    }
  };

  const updateTitle = async (id, title) => {
    await updateDoc(doc(db, `${rootPath}/${id}`), { title });
  };

  return (
    <MDBox>
      <Dialog
        open={openGenerateDialog}
        onClose={handleCloseGenerateDialog}
        aria-labelledby="generate-dialog-title"
        aria-describedby="generate-dialog-description"
        fullWidth
      >
        <DialogTitle id="generate-dialog-title">
          Generate Meeting Minutes
        </DialogTitle>
        <DialogContent id="generate-dialog-description">
          <MDBox pt={2} px={2}>
            <MDBox component="form">
              <MDBox mb={2}>
                <MDInput
                  variant="standard"
                  label="Transcript"
                  value={targetTranscript}
                  onChange={event => {
                    setTargetTranscript(event.target.value);
                  }}
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {transcripts.map(transcript => (
                    <MenuItem value={transcript.id}>
                      {transcript.title !== ''
                        ? transcript.title
                        : transcript.filename}
                    </MenuItem>
                  ))}
                </MDInput>
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                variant="standard"
                label="Template"
                value={targetTemplate}
                onChange={event => {
                  setTargetTemplate(event.target.value);
                }}
                select
                fullWidth
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {templates.map(template => (
                  <MenuItem value={template.id}>
                    {template.title !== '' ? template.title : template.filename}
                  </MenuItem>
                ))}
              </MDInput>
            </MDBox>
            <MDBox>
              <MDInput
                variant="standard"
                label="Meeting Minute Format"
                value={outputFormat}
                onChange={event => {
                  setOutputFormat(event.target.value);
                }}
                select
                fullWidth
                required
              >
                <MenuItem value="txt">Text</MenuItem>
                <MenuItem value="md">Markdown</MenuItem>
                <MenuItem value="docx">Microsoft docx</MenuItem>
              </MDInput>
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton variant="text" onClick={handleCloseGenerateDialog}>
            Cancel
          </MDButton>
          <MDButton variant="text" onClick={generateMeetingMinutes}>
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Are you sure delete this?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" onClick={handleCloseDeleteDialog}>
            Cancel
          </MDButton>
          <MDButton variant="text" onClick={handleDelete} autoFocus>
            OK
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDDataTable
        action={
          <MDBox ml={2}>
            <MDButton
              variant="contained"
              color="info"
              onClick={handleOpenGenerateDialog}
            >
              <Icon
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                fontSize="medium"
              >
                add
              </Icon>
              &nbsp;Request Generate
            </MDButton>
          </MDBox>
        }
        table={{
          columns: [
            { Header: 'title', accessor: 'title', width: '25%' },
            { Header: 'file', accessor: 'filename', width: '30%' },
            { Header: 'error', accessor: 'error' },
            {
              Header: 'Created Date',
              accessor: 'createdDate',
            },
            { Header: 'delete', accessor: 'delete', width: '15%' },
          ],
          rows: [
            ...meetingMinutes.map(data => ({
              title: (
                <TextField
                  variant="standard"
                  value={data.title}
                  onChange={e => {
                    setMeetingMinutes(
                      meetingMinutes.map(i => {
                        const d = i;
                        if (d.id === data.id) {
                          d.title = e.target.value;
                        }
                        return d;
                      }),
                    );
                  }}
                  onBlur={e => updateTitle(data.id, e.target.value)}
                  fullWidth
                />
              ),
              filename: data.loading ? (
                <CircularProgress size={20} color="info" />
              ) : (
                <MDTypography
                  sx={{ cursor: 'pointer' }}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={() =>
                    handleDownload(data.url, data.id, data.filename)
                  }
                >
                  {data.filename}
                </MDTypography>
              ),
              error: data.error ? (
                <Tooltip title={data.error}>
                  <Icon
                    sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                    fontSize="small"
                    color="error"
                  >
                    report_problem
                  </Icon>
                </Tooltip>
              ) : (
                ''
              ),
              createdDate: data.created
                ? data.created.toDate().toISOString().split('T')[0]
                : '',
              delete: (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  onClick={() => handleOpenDeleteDialog(data)}
                >
                  <Icon
                    sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                    fontSize="small"
                  >
                    delete
                  </Icon>
                </IconButton>
              ),
            })),
          ],
        }}
      />
    </MDBox>
  );
}

export default MeetingMinutes;
