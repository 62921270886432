import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import { useThemeContext } from 'contexts/theme';
import { RouterProvider } from 'react-router-dom';
import { routes } from 'routers';

function App() {
  const [context] = useThemeContext();
  const { loading, darkMode } = context;

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Backdrop sx={{ zIndex: theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="info" />
      </Backdrop>
      <CssBaseline />
      <RouterProvider router={routes} />
    </ThemeProvider>
  );
}

export default App;
