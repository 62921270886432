import { Icon } from '@mui/material';
import Home from 'layouts/Home';
import Dashboard from 'pages/Dashboard';
import MeetingMinutes from 'pages/MeetingMinutes';
import NotFound from 'pages/NotFound';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import Templates from 'pages/Templates';
import Transcripts from 'pages/Transcripts';
import Users from 'pages/Users';
import { createBrowserRouter } from 'react-router-dom';
import Private from 'routers/Private';
import Public from 'routers/Public';

export const routes = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        path: '',
        element: <Public />,
        children: [
          {
            path: '',
            element: <SignIn />,
          },
          {
            path: 'sign-up',
            element: <SignUp />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
        ],
      },
      {
        element: <Private />,
        children: [
          {
            path: 'home',
            element: (
              <Home>
                <Dashboard />
              </Home>
            ),
          },
          {
            path: 'profile',
            element: (
              <Home>
                <Profile />
              </Home>
            ),
          },
          {
            path: 'templates',
            element: (
              <Home>
                <Templates />
              </Home>
            ),
          },
          {
            path: 'transcripts',
            element: (
              <Home>
                <Transcripts />
              </Home>
            ),
          },
          {
            path: 'meeting-minutes',
            element: (
              <Home>
                <MeetingMinutes />
              </Home>
            ),
          },
          {
            path: 'users',
            element: (
              <Home>
                <Users />
              </Home>
            ),
          },
        ],
      },
    ],
  },
]);

export const menus = [
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/home',
  },
  {
    type: 'collapse',
    name: 'Transcripts',
    key: 'transcripts',
    icon: <Icon fontSize="small">record_voice_over</Icon>,
    route: '/transcripts',
  },
  {
    type: 'collapse',
    name: 'Templates',
    key: 'templates',
    icon: <Icon fontSize="small">ballot</Icon>,
    route: '/templates',
  },
  {
    type: 'collapse',
    name: 'Meeting Minuts',
    key: 'meetingMinutes',
    icon: <Icon fontSize="small">insights</Icon>,
    route: '/meeting-minutes',
  },
];
