import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

const { size } = typography;
const { text } = colors;

const dialogContentText = {
  styleOverrides: {
    root: {
      fontSize: size.md,
      color: text.main,
    },
  },
};

export default dialogContentText;
