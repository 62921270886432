import colors from 'assets/theme-dark/base/colors';
import pxToRem from 'assets/theme-dark/functions/pxToRem';

const { transparent, white } = colors;

const select = {
  styleOverrides: {
    select: {
      'display': 'grid',
      'alignItems': 'center',
      'padding': `0 ${pxToRem(12)} !important`,

      '& .Mui-selected': {
        backgroundColor: transparent.main,
      },
      'color': white.main,
    },

    selectMenu: {
      background: 'none',
      height: 'none',
      minHeight: 'none',
      overflow: 'unset',
    },

    icon: {
      display: 'none',
    },
  },
};

export default select;
