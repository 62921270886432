import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import typography from 'assets/theme/base/typography';

import PropTypes from 'prop-types';

function Footer({ light }) {
  const { size } = typography;

  return (
    <Box component="footer" position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          justifyContent="center"
          alignItems="center"
          px={1.5}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            color={light ? 'white' : 'text'}
            fontSize={size.sm}
          >
            Copyright &copy;
            <Link href="https://www.qualitax.io/" target="_blank">
              <Typography
                variant="button"
                fontWeight="medium"
                color={light ? 'white' : 'dark'}
              >
                &nbsp;Consianimis&nbsp;
              </Typography>
            </Link>
            {new Date().getFullYear()}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
