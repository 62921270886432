/* eslint-disable no-param-reassign */
import CryptoJS from 'crypto-js';
import * as forge from 'node-forge';

const aes = CryptoJS.AES;

const options = {
  aesStandard: 'AES-CBC',
  rsaStandard: 'RSA-OAEP',
  messageDigest: 'sha256',
  aesKeySize: 256,
  aesIvSize: 32,
};

const toArray = obj => (Array.isArray(obj) ? obj : [obj]);

/**
 * Generates RSA keypair
 *
 * @param {function} callback Function that gets called when keys are generated
 * @param {int} [keySize=4096] Integer that determines the RSA key size
 *
 * @example
 * rsa.generateKeyPair(keys => console.log(keys), 1024);
 *
 * @method
 */
function generateKeyPair(callback, keySize) {
  // Generate key pair using forge
  forge.pki.rsa.generateKeyPair(
    {
      bits: keySize || 4096,
      workers: -1,
    },
    (err, keyPair) => {
      // Cast key pair to PEM format
      keyPair.publicKey = forge.pki.publicKeyToPem(keyPair.publicKey);
      keyPair.privateKey = forge.pki.privateKeyToPem(keyPair.privateKey);
      callback(keyPair);
    },
  );
}

/**
 * Returns message digest by type
 *
 * @param {String} messageDigest Message digest type as string
 *
 * @return {Object} Initialized message digest
 * @method
 */
function getMessageDigest(messageDigest) {
  switch (messageDigest) {
    case 'sha1':
      return forge.md.sha1.create();

    case 'sha256':
      return forge.md.sha256.create();

    case 'sha384':
      return forge.md.sha384.create();

    case 'sha512':
      return forge.md.sha512.create();

    case 'md5':
      return forge.md.md5.create();

    default:
      console.warn(
        'Message digest "'.concat(
          options.md,
          '" not found. Using default message digest "sha1" instead',
        ),
      );
      return forge.md.sha1.create();
  }
}

/**
 * Parses hybrid-crypto-js signature
 *
 * @param {String} signature Signature string. Either JSON formatted string or plain signature
 *
 * @return {Object} Parsed signature
 * @method
 */
function parseSignature(signature) {
  try {
    return JSON.parse(signature);
  } catch (e) {
    return {
      signature,
      messageDigest: 'sha1',
    };
  }
}

/**
 * Signs a message
 *
 * @param {String} privateKey Private key in PEM format
 * @param {String} message Message to sign
 *
 * @return {String} Signature and meta data as a JSON formatted string
 * @method
 */

export function getSignature(privateKey, message) {
  // Create SHA-1 checksum
  const checkSum = getMessageDigest(options.messageDigest);

  checkSum.update(message, 'utf8');

  // Accept both PEMs and forge private key objects
  if (typeof privateKey === 'string')
    privateKey = forge.pki.privateKeyFromPem(privateKey);
  const sign = privateKey.sign(checkSum);
  const sign64 = forge.util.encode64(sign);

  // Return signature in JSON format
  return JSON.stringify({
    signature: sign64,
    messageDigest: options.messageDigest,
  });
}

/**
 * Verifies a message
 *
 * @param {String} publicKey Public key in PEM format
 * @param {String} _signature Signature in JSON string format
 * @param {String} decrypted Decrypted message
 *
 * @return {Boolean} Tells whether verification were successful or not
 * @method
 */
export function verify(publicKey, _signature, decrypted) {
  // Return false if no signature is defined
  if (!_signature) return false;

  // Parse signature object into actual signature and message digest type
  const parse = parseSignature(_signature);
  let { signature } = parse;
  const { messageDigest } = parse;

  // Create SHA-1 checksum
  const checkSum = getMessageDigest(messageDigest);
  checkSum.update(decrypted, 'utf8');

  // Base64 decode signature
  signature = forge.util.decode64(signature);

  // Accept both PEMs and forge private key objects
  if (typeof publicKey === 'string')
    publicKey = forge.pki.publicKeyFromPem(publicKey);

  // Verify signature
  return publicKey.verify(checkSum.digest().getBytes(), signature);
}

/**
 * Returns fingerprint for any public key
 *
 * @param {Object} publicKey Forge public key object
 *
 * @return {String} Public key's fingerprint
 * @method
 */
function getFingerprint(publicKey) {
  return forge.pki.getPublicKeyFingerprint(publicKey, {
    encoding: 'hex',
    delimiter: ':',
  });
}

/**
 * Validates encrypted message
 *
 * @param {String} encrypted Encrypted message
 *
 * @method
 */
function validate(encrypted) {
  const p = JSON.parse(encrypted);
  if (
    // Check required properties
    !(
      Object.hasOwnProperty.call(p, 'iv') &&
      Object.hasOwnProperty.call(p, 'keys') &&
      Object.hasOwnProperty.call(p, 'cipher')
    )
  )
    throw new Error('Encrypted message is not valid');
}

/**
 * Encrypts a message using public RSA key and optional signature
 *
 * @param {String[]} publicKeys Public keys in PEM format
 * @param {String} message Message to encrypt
 * @param {String} signature Optional signature
 *
 * @return {{iv:string, keys:Object, cipher:string, signature:any, tag:any}} Encrypted message and metadata as a JSON formatted string
 * @method
 */
function encrypt(publicKeys, message, signature) {
  // Generate flat array of keys
  publicKeys = toArray(publicKeys);

  // Map PEM keys to forge public key objects
  publicKeys = publicKeys.map(key =>
    typeof key === 'string' ? forge.pki.publicKeyFromPem(key) : key,
  );

  // Generate random keys
  const iv = forge.random.getBytesSync(options.aesIvSize);
  const key = forge.random.getBytesSync(options.aesKeySize / 8);

  // Encrypt random key with all of the public keys
  const encryptedKeys = {};
  publicKeys.forEach(publicKey => {
    const encryptedKey = publicKey.encrypt(key, options.rsaStandard);
    const fingerprint = getFingerprint(publicKey);
    encryptedKeys[fingerprint] = forge.util.encode64(encryptedKey);
  });

  // Create buffer and cipher
  const buffer = forge.util.createBuffer(message, 'utf8');
  const cipher = forge.cipher.createCipher(options.aesStandard, key);

  // Actual encryption
  cipher.start({
    iv,
  });
  cipher.update(buffer);
  cipher.finish();

  // Attach encrypted message int payload
  const payload = {};
  payload.iv = forge.util.encode64(iv);
  payload.keys = encryptedKeys;
  payload.cipher = forge.util.encode64(cipher.output.data);
  payload.signature = signature;
  payload.tag =
    cipher.mode.tag && forge.util.encode64(cipher.mode.tag.getBytes());

  // Return encrypted message
  return JSON.stringify(payload);
}
/**
 * Decrypts a message using private RSA key
 *
 * @param {String} privateKey Private key in PEM format
 * @param {String} encrypted Message to decrypt
 *
 * @return {Object} Decrypted message and metadata as a JSON object
 * @method
 */
function decrypt(privateKey, encrypted) {
  // Validate encrypted message
  validate(encrypted);

  // Parse encrypted string to JSON
  const payload = JSON.parse(encrypted);

  // Accept both PEMs and forge private key objects
  // Cast PEM to forge private key object
  if (typeof privateKey === 'string')
    privateKey = forge.pki.privateKeyFromPem(privateKey);

  // Get key fingerprint
  const fingerprint = getFingerprint(privateKey);

  // Get encrypted keys and encrypted message from the payload
  const encryptedKey = payload.keys[fingerprint];

  // Log error if key wasn't found
  if (!encryptedKey)
    throw new Error(
      "RSA fingerprint doesn't match with any of the encrypted message's fingerprints",
    );

  // Get bytes of encrypted AES key, initialization vector and cipher
  const keyBytes = forge.util.decode64(encryptedKey);
  const iv = forge.util.decode64(payload.iv);
  const cipher = forge.util.decode64(payload.cipher);
  const tag = payload.tag && forge.util.decode64(payload.tag);

  // Use RSA to decrypt AES key
  const key = privateKey.decrypt(keyBytes, options.rsaStandard);

  // Create buffer and decipher
  const buffer = forge.util.createBuffer(cipher);
  const decipher = forge.cipher.createDecipher(options.aesStandard, key);

  // Actual decryption
  decipher.start({
    iv,
    tag,
  });
  decipher.update(buffer);
  decipher.finish();

  // Return utf-8 encoded bytes
  const bytes = decipher.output.getBytes();
  const decrypted = forge.util.decodeUtf8(bytes);

  const output = {};
  output.message = decrypted;
  output.signature = payload.signature;
  return output;
}

export const generateKeys = () =>
  new Promise(resolve => {
    generateKeyPair(keyPair => {
      resolve({
        public: keyPair.publicKey,
        private: keyPair.privateKey,
      });
    });
  });

export const encryptData = async (data, key) =>
  new Promise(resolve => {
    resolve(encrypt(key, data));
  });

export const decryptData = async (data, key) =>
  new Promise(resolve => {
    resolve(decrypt(key, data));
  });

export const encryptPrivateKey = async (pair, password) =>
  new Promise(resolve => {
    const priv = pair.private;
    const encpriv = aes.encrypt(priv, password).toString();
    resolve({ public: pair.public, private: encpriv });
  });

export const decryptPrivateKey = async (pair, password) =>
  new Promise(resolve => {
    try {
      const encpriv = pair.private;
      let decrypted = aes.decrypt(encpriv, password);
      decrypted = decrypted.toString(CryptoJS.enc.Utf8);
      resolve({ public: pair.public, private: decrypted });
    } catch (e) {
      console.error(e);
      resolve(false);
    }
  });
