import { auth, db } from 'config/firebase';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  increment,
} from 'firebase/firestore';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  decryptPrivateKey,
  encryptPrivateKey,
  generateKeys,
} from 'services/crypto';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

function AuthContextProvider({ children }) {
  // firebase user
  const [user, setUser] = useState(null);

  // user profile
  const [userData, setUserData] = useState(null);

  const [userKey, setUserKey] = useState('');

  // user role
  const [isAdmin, setIsAdmin] = useState(false);

  // is logged in
  const [isSignedIn, setIsSignedIn] = useState(false);

  async function saveUserData(uid, data) {
    await setDoc(doc(db, 'users', uid), data, { merge: true });
  }

  async function readUserData(uid, password) {
    const ref = doc(db, 'users', uid);
    const snap = await getDoc(ref);
    const data = snap.data();
    const key = await decryptPrivateKey(data.key, password);

    if (snap.exists) {
      return {
        ...data,
        key,
      };
    }
    return null;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async firebaseUser => {
      if (firebaseUser && userKey) {
        setUser(firebaseUser);
        const firestoreUser = await readUserData(firebaseUser.uid, userKey);
        setUserData(firestoreUser);
        setIsAdmin(firestoreUser.isAdmin ?? false);
        setIsSignedIn(true);
        if (userData?.reset ?? false) {
          const keyPair = await generateKeys();
          const encKeyPair = await encryptPrivateKey(keyPair, userKey);
          saveUserData(user.id, { key: encKeyPair, reset: deleteField() });
        }
      } else {
        setUser(null);
        setUserData(null);
        setIsAdmin(false);
        setIsSignedIn(false);
      }
    });
    return unsubscribe();
  }, [user, userKey]);

  function errorHandler(err) {
    switch (err.code) {
      case 'auth/email-already-in-use':
      case 'auth/email-already-exists':
        return 'Email already exists';
      case 'auth/user-not-found':
        return 'User not found';
      case 'auth/invalid-email':
        return 'Invalid email';
      case 'auth/wrong-password':
        return 'Wrong password';
      case 'auth/invalid-password':
        return 'Invalid password';
      case 'auth/internal-error':
        return 'Internal Server error';
      default:
        return 'Failed to authenticate';
    }
  }

  async function signIn(email, password) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // if (!credential.user.emailVerified) {
      //   await firebaseSignOut();
      //   throw new Error('Email is not verified');
      // }
      setUserKey(password);
    } catch (err) {
      console.error(err);
      throw new Error(errorHandler(err));
    }
  }

  async function signUp(email, password, firstName, lastName, companyName) {
    try {
      const credential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      if (credential.user) {
        const firebaseUser = credential.user;
        const keyPair = await generateKeys();
        const encKeyPair = await encryptPrivateKey(keyPair, password);
        const date = new Date();
        await saveUserData(firebaseUser.uid, {
          uid: firebaseUser.uid,
          email,
          firstName,
          lastName,
          companyName,
          key: encKeyPair,
          isAdmin: false,
          credit: 30,
          created: serverTimestamp(),
          createdDate: date.getDate(),
        });
        // if (!credential.user.emailVerified) {
        //   await firebaseSignOut();
        //   throw new Error('Email is not verified');
        // }
        setUserKey(password);
      }
      return;
    } catch (err) {
      console.error(err);
      throw new Error(errorHandler(err));
    }
  }

  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      await getDocs(
        query(collection(db, 'users'), where('email', '==', email)),
      ).then(snapshots => {
        snapshots.docs.forEach(snapshot => {
          updateDoc(snapshot, { reset: true });
        });
      });
    } catch (err) {
      console.error(err);
      throw new Error('Failed to send email');
    }
  }

  async function changePassword(email, currentPassword, newPassword) {
    try {
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        currentPassword,
      );
      await updatePassword(credential.user, newPassword);
      const key = await encryptPrivateKey(userData.key, newPassword);
      saveUserData(credential.user.uid, { key });
      setUserKey(newPassword);
    } catch (err) {
      console.error(err);
      throw new Error(errorHandler(err));
    }
  }

  async function changeEmail(email, password) {
    try {
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      await updateEmail(credential.user, email);
    } catch (err) {
      console.error(err);
      throw new Error(errorHandler(err));
    }
  }

  async function updateUserData(email, firstName, lastName, companyName) {
    try {
      const data = { id: user.uid, email, firstName, lastName, companyName };
      saveUserData(user.uid, data);
    } catch (err) {
      console.error(err);
      throw new Error('Failed to update profile');
    }
  }

  async function signOut() {
    try {
      await firebaseSignOut(auth);
      setUserKey('');
    } catch (err) {
      console.error(err);
      throw new Error('Failed to Sign out');
    }
  }

  async function removeUser() {
    try {
      await deleteUser(user);
      setUserKey('');
    } catch (err) {
      console.error(err);
      throw new Error(errorHandler(err));
    }
  }

  async function useCredit(credit = 1) {
    if (userData.credit < 1) {
      throw new Error('Not enough credit');
    }
    try {
      await saveUserData(user.uid, { credit: increment(credit * -1) });
    } catch (err) {
      console.error(err);
      throw new Error('failed to use credit');
    }
  }

  const value = useMemo(
    () => ({
      user,
      userData,
      isSignedIn,
      isAdmin,
      signIn,
      signUp,
      resetPassword,
      changePassword,
      changeEmail,
      updateUserData,
      signOut,
      removeUser,
      useCredit,
    }),
    [user, userData, isSignedIn, isAdmin],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context;
}

export { AuthContextProvider, useAuthContext };
