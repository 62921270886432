export function checkEmail(email) {
  const emaiPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
  if (!email.match(emaiPattern)) {
    return 'Invalid email address';
  }
  return '';
}

export function checkPassword(password) {
  const passPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (!password.match(passPattern)) {
    return 'password must be at least 8 characters with 1 special character, 1 upper case letter and 1 number';
  }
  return '';
}
