import { Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDTypography from 'components/MDTypography';
import { useAuthContext } from 'contexts/auth';
import { setLoading, useThemeContext } from 'contexts/theme';
import Page from 'layouts/Page';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { checkEmail, checkPassword } from 'utils/stringUtil';

export default function SignIn() {
  const [themeContext, dispatch] = useThemeContext();
  const { loading } = themeContext;

  const { signIn } = useAuthContext();
  const [signInForm, setSignInForm] = useState({
    email: '',
    password: '',
  });
  const [signInError, setSignInError] = useState({
    email: '',
    password: '',
    signIn: '',
    form: '',
  });
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const validateForm = (name, value) => {
    if (name === 'email') {
      setSignInError({ ...signInError, [name]: checkEmail(value) });
    }
    if (name === 'password') {
      setSignInError({ ...signInError, [name]: checkPassword(value) });
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setSignInForm(values => ({ ...values, [name]: value }));
    validateForm(name, value);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!loading) {
      setLoading(dispatch, true);
    }

    setSignInError({ email: '', password: '', signIn: '', form: '' });
    setOpenErrorSnackbar(false);

    Object.entries(signInForm).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(signInForm, key)) {
        setSignInError({ ...signInError, [key]: 'Not exist' });
      }
      validateForm(key, value);
    });

    if (Object.entries(signInError).filter(v => v[1] !== '').length > 0) {
      setLoading(dispatch, false);
      return;
    }

    const { email, password } = signInForm;

    setSignInError({ ...signInError, form: '' });
    await signIn(email, password)
      .catch(err => {
        setSignInError({ ...signInError, form: err.message });
        setOpenErrorSnackbar(true);
        setLoading(dispatch, false);
      })
      .finally(() => {
        setLoading(dispatch, false);
      });
  };

  return (
    <Page>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={signInForm.email}
                error={!!signInError.email}
                helperText={signInError.email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label="Password"
                variant="standard"
                fullWidth
                required
                onChange={handleChange}
                value={signInForm.password}
                error={!!signInError.password}
                helperText={signInError.password}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
              >
                sign in
              </MDButton>
            </MDBox>
            <Grid
              container
              justifyContent="space-around"
              direction="row"
              alignItems="center"
            >
              <Grid item xs>
                <MDTypography
                  component={Link}
                  to="/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot Password?
                </MDTypography>
              </Grid>
              <Grid item xs>
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{' '}
                  <MDTypography
                    component={Link}
                    to="/sign-up"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="error"
        dateTime=""
        icon="notifications"
        title="ERROR"
        content={signInError.form}
        open={openErrorSnackbar}
        close={() => {
          setOpenErrorSnackbar(false);
          setSignInError({ ...signInError, form: '' });
        }}
      />
    </Page>
  );
}
