import { Card, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDTypography from 'components/MDTypography';
import { useAuthContext } from 'contexts/auth';
import { setLoading, useThemeContext } from 'contexts/theme';
import { useState } from 'react';
import { checkEmail, checkPassword } from 'utils/stringUtil';

function Profile() {
  const [themeContext, dispatch] = useThemeContext();
  const { loading } = themeContext;

  const { userData, changeEmail, changePassword, updateUserData, removeUser } =
    useAuthContext();
  const [profileForm, setProfileForm] = useState({
    email: userData.email,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    firstName: userData.firstName,
    lastName: userData.lastName,
    companyName: userData.companyName,
  });
  const [profileError, setProfileError] = useState({
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    agreement: '',
    form: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openDeleteDialog, setDeleteDialog] = useState(false);

  const validateForm = (name, value) => {
    if (name === 'email') {
      setProfileError({ ...profileError, [name]: checkEmail(value) });
    }
    if (name === 'currentPassword') {
      setProfileError({ ...profileError, [name]: checkPassword(value) });
    }
    if (name === 'newPassword') {
      if (value !== '') {
        setProfileError({ ...profileError, [name]: checkPassword(value) });
      }
    }
    if (name === 'confirmPassword') {
      if (profileForm.newPassword !== '') {
        setProfileError({
          ...profileError,
          [name]: profileForm.newPassword !== value ? 'Password not match' : '',
        });
      }
    }
    if (name === 'firstName' || name === 'lastName' || name === 'companyName') {
      setProfileError({ ...profileError, [name]: !value ? "It's empty" : '' });
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setProfileForm(values => ({ ...values, [name]: value }));
    validateForm(name, value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!loading) {
      setLoading(dispatch, true);
    }
    setProfileError({
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      companyName: '',
      agreement: '',
      form: '',
    });
    setOpenSnackbar(false);

    Object.entries(profileForm).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(profileForm, key)) {
        setProfileError({ ...profileError, [key]: 'Not exist' });
      }
      validateForm(key, value);
    });

    if (Object.entries(profileError).filter(v => v[1] !== '').length > 0) {
      setLoading(dispatch, false);
      return;
    }

    const {
      email,
      currentPassword,
      newPassword,
      firstName,
      lastName,
      companyName,
    } = profileForm;

    try {
      if (userData.email !== email) {
        await changeEmail(email, currentPassword);
      }

      if (newPassword !== '' && newPassword !== currentPassword) {
        await changePassword(email, currentPassword, newPassword);
      }

      await updateUserData(email, firstName, lastName, companyName);
    } catch (err) {
      setProfileError({ ...profileError, form: err.message });
      setOpenSnackbar(true);
      setLoading(dispatch, false);
    } finally {
      setLoading(dispatch, false);
      setOpenSnackbar(true);
    }
  };

  return (
    <MDBox mt={6} mb={3}>
      <Dialog open={openDeleteDialog} onClose={() => setDeleteDialog(false)}>
        <DialogTitle>Are you sure delete account?</DialogTitle>
        <DialogActions>
          <MDButton variant="text" onClick={() => setDeleteDialog(false)}>
            Cancel
          </MDButton>
          <MDButton
            variant="text"
            color="error"
            onClick={async () => {
              await removeUser();
              window.refresh();
            }}
            autoFocus
          >
            CONFIRM
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={profileError.form ? 'error' : 'success'}
        dateTime=""
        icon="notifications"
        title={profileError.form ? 'ERROR' : 'SUCCESS'}
        content={
          profileError.form ? profileError.form : 'Profile Successfully updated'
        }
        open={openSnackbar}
        close={() => {
          setOpenSnackbar(false);
          setProfileError({ ...profileError, form: '' });
        }}
      />
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} lg={8}>
          <Card>
            <MDBox p={2}>
              <MDTypography variant="h5">Profile</MDTypography>
            </MDBox>
            <MDBox pt={2} px={2}>
              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    name="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    required
                    onChange={handleChange}
                    value={profileForm.email}
                    error={!!profileError.email}
                    helperText={profileError.email}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="currentPassword"
                    label="Current Password"
                    variant="standard"
                    fullWidth
                    required
                    onChange={handleChange}
                    value={profileForm.currentPassword}
                    error={!!profileError.currentPassword}
                    helperText={profileError.currentPassword}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="newPassword"
                    label="New Password"
                    variant="standard"
                    fullWidth
                    onChange={handleChange}
                    value={profileForm.newPassword}
                    error={!!profileError.newPassword}
                    helperText={profileError.newPassword}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    variant="standard"
                    fullWidth
                    onChange={handleChange}
                    value={profileForm.confirmPassword}
                    error={!!profileError.confirmPassword}
                    helperText={profileError.confirmPassword}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    name="companyName"
                    label="Company Name"
                    variant="standard"
                    fullWidth
                    required
                    onChange={handleChange}
                    value={profileForm.companyName}
                    error={!!profileError.companyName}
                    helperText={profileError.companyName}
                  />
                </MDBox>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="firstName"
                        label="First Name"
                        variant="standard"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={profileForm.firstName}
                        error={!!profileError.firstName}
                        helperText={profileError.firstName}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="lastName"
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={profileForm.lastName}
                        error={!!profileError.lastName}
                        helperText={profileError.lastName}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    SAVE
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MDBox alignItems="center">
            <MDButton
              variant="text"
              color="error"
              onClick={() => setDeleteDialog(true)}
            >
              Delete Account
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Profile;
